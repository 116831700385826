body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.string {
  color: #c26a19;
}

.key {
  color: #f01d1d;
}

.mtlogo {
  margin-top: -10px;
}

.show-more {
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
  margin-bottom: 0px !important;
}

.show-more-btn {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
}

.icon {
  width: 200px;
  height: 200px;
}

.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #000;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.copyright a {
  color: white;
}

.copyright a:hover {
  color: white;
  text-decoration: underline !important;
}